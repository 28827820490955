<template>
  <v-data-table
    :expanded="tableMixin_expandedRows"
    :footer-props="{
      'items-per-page-options': [pagination.per_page],
    }"
    :items-per-page="pagination.per_page"
    :headers="tableMixin_displayedHeaders"
    :items="items"
    :mobile-breakpoint="0"
    :page="pagination.current_page"
    :server-items-length="pagination.total"
    :class="{ 'content-loading': loading }"
    :item-class="'clickable'"
    :loading="loading"
    disable-sort
    @update:page="tableMixin_changePage"
    @click:row="onRowClick"
  >
    <template v-slot:item.no="{ index }">
      {{ index + 1 + (pagination.current_page - 1) * pagination.per_page }}.
    </template>

    <template v-slot:item.role="{ item }">
      {{ $t(`users.role.${item.role}`) }}
    </template>

    <template v-slot:item.status="{ item }">
      {{ $t(`users.status.${item.status}`) }}
    </template>

    <template v-slot:item.accessed_at="{ item }">
      {{ getTimeFromDate(item.accessed_at) }}
    </template>

    <template v-slot:item.actions="{ item }">
      <BaseActionMenu :actions="getRowActions(item)" :item="item" />
    </template>

    <template v-slot:expanded-item="{ item }">
      <BaseExpandedTableRow
        :colspan="tableMixin_displayedHeaders.length"
        :headers="tableMixin_hiddenHeaders"
        :item="item"
      >
        <template v-slot:item.role>
          {{ $t(`users.role.${item.role}`) }}
        </template>

        <template v-slot:item.status>
          {{ $t(`users.status.${item.status}`) }}
        </template>

        <template v-slot:item.accessed_at>
          {{ getTimeFromDate(item.accessed_at) }}
        </template>
      </BaseExpandedTableRow>
    </template>
  </v-data-table>
</template>

<script>
import tableMixin from '@/mixins/table-mixin';
import BaseActionMenu from '@/components/base/BaseActionMenu';
import BaseExpandedTableRow from '@/components/base/BaseExpandedTableRow';
import { getTimeFromDate } from '@/util/time';

export default {
  name: 'UserTable',

  components: {
    BaseExpandedTableRow,
    BaseActionMenu,
  },

  mixins: [tableMixin],

  props: {
    items: {
      type: Array,
      required: true,
    },
    pagination: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    headers() {
      return [
        {
          text: this.$t('general.no'),
          value: 'no',
          hidden: 'smAndDown',
          hideInExpandedRow: true,
        },
        {
          text: this.$t('general.personal_info.full_name'),
          value: 'personal_data.full_name',
        },
        {
          text: this.$t('general.common_contact_info.email'),
          value: 'username',
          hidden: 'xsOnly',
        },
        {
          text: this.$t('general.common_contact_info.phone'),
          value: 'personal_data.phone',
          hidden: 'xsOnly',
        },
        {
          text: this.$t('general.role'),
          value: 'role',
          hidden: 'smAndDown',
        },
        {
          text: this.$t('general.status'),
          value: 'status',
          hidden: 'smAndDown',
        },
        {
          text: this.$t('users.was_logged_in'),
          value: 'accessed_at',
          hidden: 'smAndDown',
        },
        {
          value: 'actions',
          align: 'end',
        },
      ];
    },
  },

  methods: {
    getTimeFromDate,

    onRowClick(item) {
      if (this.tableMixin_hiddenHeaders.length) {
        this.tableMixin_toggleRowExpand(item);
      } else {
        this.$emit('edit', item);
      }
    },

    getRowActions(user) {
      const actions = [];

      if (user.role === 'client' && this.tableMixin_hiddenHeaders.length) {
        actions.push({
          callback: (p) => this.$emit('edit', p),
          label: this.$t('general.controls.edit'),
          icon: 'edit',
        });
      }

      if (!this.$isCurrentUser(user.id)) {
        actions.push(
          {
            callback: (p) => this.$emit('edit-permissions', p),
            label: this.$t('employees.general.edit_role_permissions'),
            icon: 'lock',
          },
          {
            callback: (p) => this.$emit('toggle-status', p),
            label: this.$t(
              user.status === 'active' ? 'general.controls.suspend' : 'general.controls.activate',
            ),
            icon: user.status === 'active' ? 'block' : 'verified_user',
            disabled: this.$store.getters.loading[`patch:api/users/${user.id}/toggle-suspend`],
          },
        );
        // {
        //   callback: (p) => this.$emit('reset-password', p),
        //   label: this.$t('reset_password'),
        //   icon: 'mdi-lock-reset',
        //   disabled: this.$store.getters.loading[`post:api/users/${user.id}/reset-password`],
        // },
        if (!this.$isClient()) {
          actions.push({
            callback: (p) => this.$emit('impersonate', p),
            label: this.$t('general.controls.impersonate'),
            icon: 'people',
            disabled: this.$store.getters.loading[`patch:api/users/${user.id}/toggle-suspend`],
          });
        }
      }

      return actions;
    },
  },
};
</script>
