var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{class:{ 'content-loading': _vm.loading },attrs:{"expanded":_vm.tableMixin_expandedRows,"footer-props":{
    'items-per-page-options': [_vm.pagination.per_page],
  },"items-per-page":_vm.pagination.per_page,"headers":_vm.tableMixin_displayedHeaders,"items":_vm.items,"mobile-breakpoint":0,"page":_vm.pagination.current_page,"server-items-length":_vm.pagination.total,"item-class":'clickable',"loading":_vm.loading,"disable-sort":""},on:{"update:page":_vm.tableMixin_changePage,"click:row":_vm.onRowClick},scopedSlots:_vm._u([{key:"item.no",fn:function(ref){
  var index = ref.index;
return [_vm._v(" "+_vm._s(index + 1 + (_vm.pagination.current_page - 1) * _vm.pagination.per_page)+". ")]}},{key:"item.role",fn:function(ref){
  var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t(("users.role." + (item.role))))+" ")]}},{key:"item.status",fn:function(ref){
  var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t(("users.status." + (item.status))))+" ")]}},{key:"item.accessed_at",fn:function(ref){
  var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getTimeFromDate(item.accessed_at))+" ")]}},{key:"item.actions",fn:function(ref){
  var item = ref.item;
return [_c('BaseActionMenu',{attrs:{"actions":_vm.getRowActions(item),"item":item}})]}},{key:"expanded-item",fn:function(ref){
  var item = ref.item;
return [_c('BaseExpandedTableRow',{attrs:{"colspan":_vm.tableMixin_displayedHeaders.length,"headers":_vm.tableMixin_hiddenHeaders,"item":item},scopedSlots:_vm._u([{key:"item.role",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t(("users.role." + (item.role))))+" ")]},proxy:true},{key:"item.status",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t(("users.status." + (item.status))))+" ")]},proxy:true},{key:"item.accessed_at",fn:function(){return [_vm._v(" "+_vm._s(_vm.getTimeFromDate(item.accessed_at))+" ")]},proxy:true}],null,true)})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }