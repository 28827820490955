<template>
  <v-container fluid>
    <v-tabs class="elevation-1 table-wrapper">
      <v-tab @click="fetchUsers(userFilterParams)">
        {{ $t('general.users') }}
      </v-tab>

      <v-tab-item>
        <UserFilter
          :filter-params="userFilterParams"
          @change="$router.push({ name: 'users', query: $event })"
          @reset="resetUserFilters"
        />

        <UserTable
          :items="users"
          :pagination="userPagination"
          :loading="$store.getters.loading['get:api/users']"
          @edit="$router.push({ name: 'editUser', params: { id: $event.id } })"
          @update:page="onPageChange"
          @impersonate="impersonateUser"
          @toggle-status="toggleUserStatus"
          @edit-permissions="
            $router.push({ name: 'editUserPermissions', params: { id: $event.id } })
          "
        />
      </v-tab-item>
    </v-tabs>

    <BasePrimaryActionButton @click="$router.push({ name: 'createUser' })" />

    <router-view />
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import BasePrimaryActionButton from '@/components/base/BasePrimaryActionButton';
import UserTable from '@/components/tables/UserTable.vue';
import UserFilter from '@/components/filters/UserFilter';
import { getDefaultUserFilterParams } from '@/store/modules/users-module';
import { getSanitizedFilterParams } from '@/util/filter-params';

export default {
  name: 'Users',

  components: {
    BasePrimaryActionButton,
    UserFilter,
    UserTable,
  },

  created() {
    this.fetchUsers(this.$route.query);
  },

  beforeRouteUpdate(to, from, next) {
    const params = getSanitizedFilterParams(to.query);
    if (to.name === 'users' && JSON.stringify(this.userFilterParams) !== JSON.stringify(params)) {
      this.fetchUsers(to.query);
    }
    next();
  },

  computed: {
    ...mapState('users', ['users', 'userPagination', 'userFilterParams']),
  },

  methods: {
    ...mapActions('users', ['fetchUsers', 'toggleUserStatus']),
    ...mapActions('auth', ['impersonateUser']),

    onPageChange(page) {
      const query = { ...this.userFilterParams, page };
      this.$router.push({ name: 'users', query });
    },

    resetUserFilters() {
      const defaultFilters = getDefaultUserFilterParams();
      if (JSON.stringify(defaultFilters) === JSON.stringify(this.userFilterParams)) {
        return;
      }
      this.$router.push({ name: 'users', query: defaultFilters });
    },
  },
};
</script>
