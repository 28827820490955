<template>
  <v-row class="px-4">
    <v-col cols="12" sm="6" md="3">
      <v-text-field
        v-model="params.search"
        :label="$t('general.search')"
        prepend-inner-icon="search"
        clearable
        single-line
        @input="handleDebounce"
      />
    </v-col>

    <v-col cols="12" sm="6" md="9" class="d-flex align-center justify-end">
      <BaseFilterDialogAndChips
        :submitted-values="submittedValues"
        :show-reset-button="showResetButton"
        @open="copyFilterParams"
        @clear:filter="clearFilter"
        @reset="resetFilters"
        @submit="submitFilters"
      >
        <v-row dense>
          <v-col cols="12" sm="6">
            <v-select
              v-model="params.role"
              :items="userRoles"
              :label="$t('general.role')"
              clearable
            />
          </v-col>

          <v-col cols="12" sm="6">
            <v-select
              v-model="params.status"
              :items="userStatuses"
              :label="$t('general.status')"
              clearable
            />
          </v-col>
        </v-row>
      </BaseFilterDialogAndChips>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex';
import filterMixin from '@/mixins/filter-mixin';
import BaseFilterDialogAndChips from '@/components/base/BaseFilterDialogAndChips';
import { getDefaultUserFilterParams } from '@/store/modules/users-module';

export default {
  name: 'UserFilter',

  components: { BaseFilterDialogAndChips },

  mixins: [filterMixin],

  data() {
    return {
      defaultParams: getDefaultUserFilterParams(),
    };
  },

  computed: {
    ...mapGetters('users', ['userRoles', 'userStatuses']),

    submittedValues() {
      const values = [];
      const { role, status } = this.filterParams;

      if (role) {
        values.push({
          key: 'role',
          value: this.$t(`users.role.${role}`),
          label: this.$t('general.role'),
        });
      }

      if (status) {
        values.push({
          key: 'status',
          value: this.$t(`users.status.${status}`),
          label: this.$t('general.status'),
        });
      }

      return values;
    },
  },

  methods: {
    resetFilters() {
      this.params.search = '';
      this.$emit('change', this.defaultParams);
    },
  },
};
</script>
